<template>
  <page-header-wrapper>
    <a-card>
      <div class="table-page-search-wrapper">
        <a-form layout="inline" class="table-head-ground">
          <div class="table-layer">
            <a-form-item label="" class="table-head-layout" style="max-width:350px;min-width:300px">
              <a-range-picker
                @change="onChange"
                :show-time="{ format: 'HH:mm:ss' }"
                format="YYYY-MM-DD HH:mm:ss"
                :disabled-date="disabledDate"
                :placeholder="['创建开始日期', '创建结束日期']"
              >
                <a-icon slot="suffixIcon" type="sync" />
              </a-range-picker>
            </a-form-item>

            <a-form-item label="" class="table-head-layout" style="max-width:350px;min-width:300px">
              <a-range-picker
                @change="onChangeTime"
                :show-time="{ format: 'HH:mm:ss' }"
                format="YYYY-MM-DD HH:mm:ss"
                :disabled-date="disabledDate"
                :placeholder="['成功开始时间', '结束开始时间']"
              >
                <a-icon slot="suffixIcon" type="sync" />
              </a-range-picker>
            </a-form-item>

            <jeepay-text-up
              :placeholder="'支付/商户/渠道订单号'"
              :msg="searchData.unionOrderId"
              v-model="searchData.unionOrderId"
            />
            <jeepay-text-up
              :placeholder="'收款账号'"
              :msg="searchData.account"
              v-model="searchData.account"
            />
            <a-form-item label="" class="table-head-layout">
              <a-select v-model="searchData.state" placeholder="支付状态" default-value="">
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="0">订单生成</a-select-option>
                <a-select-option value="1">支付中</a-select-option>
                <a-select-option value="2">支付成功</a-select-option>
                <a-select-option value="6">订单关闭</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="" class="table-head-layout">
              <a-select v-model="searchData.ifCode" placeholder="支付方式">
                <a-select-option v-for="item in list" :key="item">{{ item }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="" class="table-head-layout">
              <a-select v-model="searchData.liquidateState" placeholder="清算状态" default-value="">
                <a-select-option value="0">未清算</a-select-option>
                <a-select-option value="1">清算成功</a-select-option>
              </a-select>
            </a-form-item>

            <span class="table-page-search-submitButtons">
              <a-button
                type="primary"
                icon="search"
                @click="queryFunc"
                :loading="btnLoading"
              >搜索</a-button
              >
              <a-button
                style="margin-left: 8px"
                icon="reload"
                @click="() => (this.searchData = {})"
              >重置</a-button
              >
              <a-button
                style="margin-left: 8px"
                type="primary"
                icon="download"
                @click="download(searchData)"
                :loading="loading"
              >收款订单导出</a-button
              >
            </span>
          </div>
        </a-form>
      </div>

      <!-- 列表渲染 -->
      <JeepayTable
        @btnLoadClose="btnLoading = false"
        ref="infoTable"
        :initData="true"
        :reqTableDataFunc="reqTableDataFunc"
        :tableColumns="tableColumns"
        :searchData="searchData"
        rowKey="payOrderId"
        :tableRowCrossColor="true"
      >
        <!-- 自定义插槽 -->
        <template slot="stateSlot" slot-scope="{ record }">
          <a-tag
            :key="record.state"
            :color="
              record.state === 0
                ? 'blue'
                : record.state === 1
                  ? 'orange'
                  : record.state === 2
                    ? 'green'
                    : record.state === 6
                      ? ''
                      : 'volcano'
            "
          >
            {{
              record.state === 0
                ? '订单生成'
                : record.state === 1
                  ? '支付中'
                  : record.state === 2
                    ? '支付成功'
                    : record.state === 3
                      ? '支付失败'
                      : record.state === 4
                        ? '已撤销'
                        : record.state === 5
                          ? '已退款'
                          : record.state === 6
                            ? '订单关闭'
                            : '未知'
            }}
          </a-tag>
          <a-tag
            v-if="record.ifCode != 'opmPay'"
            :key="record.liquidateState"
            :color="
              record.liquidateState === 0
                ? 'orange'
                : record.liquidateState === 1
                  ? 'green'
                  : record.liquidateState
            "
          >
            {{
              record.liquidateState === 0
                ? '未清算'
                : record.liquidateState === 1
                  ? '清算成功'
                  : record.liquidateState
            }}
          </a-tag>
        </template>

        <template slot="divisionStateSlot" slot-scope="{ record }">
          <span v-if="record.divisionState == 0">-</span>
          <a-tag color="orange" v-else-if="record.divisionState == 1">待分账</a-tag>
          <a-tag color="red" v-else-if="record.divisionState == 2">分账处理中</a-tag>
          <a-tag color="green" v-else-if="record.divisionState == 3">任务已结束</a-tag>
          <span v-else>未知</span>
        </template>

        <template slot="orderSlot" slot-scope="{ record }">
          <div class="order-list">
            <p><span style="color:#729ED5;background:#e7f5f7">支付</span>{{ record.payOrderId }}</p>
            <p style="margin-bottom: 0">
              <span style="color:#56cf56;background:#d8eadf">商户</span>
              <a-tooltip
                placement="bottom"
                style="font-weight: normal;"
                v-if="record.mchOrderNo.length > record.payOrderId.length"
              >
                <template slot="title">
                  <span>{{ record.mchOrderNo }}</span>
                </template>
                {{ changeStr2ellipsis(record.mchOrderNo, record.payOrderId.length) }}
              </a-tooltip>
              <span style="font-weight: normal;" v-else>{{ record.mchOrderNo }}</span>
            </p>
            <p v-if="record.channelOrderNo" style="margin-bottom: 0;margin-top: 10px">
              <span style="color:#fff;background:#E09C4D">渠道</span>
              <a-tooltip
                placement="bottom"
                style="font-weight: normal;"
                v-if="record.channelOrderNo.length > record.payOrderId.length"
              >
                <template slot="title">
                  <span>{{ record.channelOrderNo }}</span>
                </template>
                {{ changeStr2ellipsis(record.channelOrderNo, record.payOrderId.length) }}
              </a-tooltip>
              <span style="font-weight: normal;" v-else>{{ record.channelOrderNo }}</span>
            </p>
          </div>
        </template>

        <template slot="dateSlot" slot-scope="{ record }">
          <div class="order-list">
            <p><span style="color:#729ED5;background:#e7f5f7">创建</span>{{ record.createdAt }}</p>
            <p>
              <span style="color:#56cf56;background:#d8eadf">成功</span>{{ record.successTime }}
            </p>
          </div>
        </template>

        <template slot="opSlot" slot-scope="{ record }">
          <!-- 操作列插槽 -->
          <JeepayTableColumns>
            <a-button
              type="link"
              v-if="$access('ENT_PAY_ORDER_VIEW')"
              @click="detailFunc(record.id)"
            >详情</a-button
            >
            <a-button
              type="link"
              v-if="$access('ENT_PAY_ORDER_REFUND')"
              style="color: red"
              @click="openFunc(record, record.payOrderId)"
            >回调</a-button
            >
          </JeepayTableColumns>
        </template>
      </JeepayTable>
    </a-card>

    <!-- 日志详情抽屉 -->
    <template>
      <a-drawer
        width="50%"
        placement="right"
        :closable="true"
        :visible="visible"
        :title="visible === true ? '订单详情' : ''"
        @close="onClose"
      >
        <a-row justify="space-between" type="flex">
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="支付订单号">
                <a-tag color="purple">
                  {{ detailData.payOrderId }}
                </a-tag>
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="商户号">
                {{ detailData.mchNo }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="商户订单号">
                {{ detailData.mchOrderNo }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="商户名称">
                {{ detailData.mchName }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="应用APPID">
                {{ detailData.appId }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="收款账号">
                {{ detailData.account }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="订单状态">
                <a-tag
                  :color="
                    detailData.state === 0
                      ? 'blue'
                      : detailData.state === 1
                        ? 'orange'
                        : detailData.state === 2
                          ? 'green'
                          : detailData.state === 6
                            ? ''
                            : 'volcano'
                  "
                >
                  {{
                    detailData.state === 0
                      ? '订单生成'
                      : detailData.state === 1
                        ? '支付中'
                        : detailData.state === 2
                          ? '支付成功'
                          : detailData.state === 3
                            ? '支付失败'
                            : detailData.state === 4
                              ? '已撤销'
                              : detailData.state === 5
                                ? '已退款'
                                : detailData.state === 6
                                  ? '订单关闭'
                                  : '未知'
                  }}
                </a-tag>
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="清算状态">
                <a-tag
                  :color="
                    detailData.liquidateState === 0
                      ? 'orange'
                      : detailData.liquidateState === 1
                        ? 'green'
                        : detailData.liquidateState
                  "
                >
                  {{
                    detailData.liquidateState === 0
                      ? '未清算'
                      : detailData.liquidateState === 1
                        ? '清算成功'
                        : detailData.liquidateState
                  }}
                </a-tag>
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="支付金额">
                <a-tag color="green">
                  {{ detailData.amount }}
                </a-tag>
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions
            ><a-descriptions-item
              label="手续费"
            ><a-tag color="pink">{{
              detailData.mchFeeAmount
            }}</a-tag></a-descriptions-item
            ></a-descriptions
            >
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="支付错误码">
                {{ detailData.errCode }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="支付错误描述">
                {{ detailData.errMsg }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="订单失效时间">
                {{ detailData.expirationTime }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="支付成功时间">
                {{ detailData.successTime }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="创建时间">
                {{ detailData.createdAt }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="更新时间">
                {{ detailData.updatedAt }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-divider />
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="商品标题">
                {{ detailData.subject }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="商品描述">
                {{ detailData.body }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="接口代码">
                {{ detailData.ifCode }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="货币代码">
                {{ detailData.currency }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="客户端IP">
                {{ detailData.clientIp }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="用户标识">
                {{ detailData.channelUser }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="渠道订单号">
                {{ detailData.channelOrderNo }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="异步通知地址">
                {{ detailData.notifyUrl }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
        </a-row>
      </a-drawer>
    </template>

    <!-- 回调弹出框 -->
    <a-modal :visible="isShow" title="回调" @cancel="cancel">
      <span style="color: red;font-size:16px">确定回调吗?</span>
      <template #footer>
        <a-button key="back" @click="handleCancel">取消</a-button>
        <a-button key="submit" type="primary" @click="handleOk">确认</a-button>
      </template>
    </a-modal>
  </page-header-wrapper>
</template>
<script>
// import axios from 'axios'
// import storage from 'store'
// import appConfig from '@/config/appConfig'
import JeepayTextUp from '@/components/JeepayTextUp/JeepayTextUp' // 文字上移组件
import JeepayTable from '@/components/JeepayTable/JeepayTable'
import JeepayTableColumns from '@/components/JeepayTable/JeepayTableColumns'
import RefundModal from './RefundModal' // 退款弹出框
import { API_URL_PAY_ORDER_LIST, req, payOrder, ifCodeList, download } from '@/api/manage'
import moment from 'moment'
// import query from '@/utils/query'
import { formatAmount } from '@/utils/number.js'

// eslint-disable-next-line no-unused-vars
const tableColumns = [
  {
    key: 'amount',
    title: '支付金额',
    dataIndex: 'amount',
    customRender: text => formatAmount(text)
  },
  {
    key: 'mchFeeAmount',
    dataIndex: 'mchFeeAmount',
    title: '手续费',
    customRender: text => formatAmount(text)
  },
  { key: 'orderNo', title: '订单号', scopedSlots: { customRender: 'orderSlot' }, width: '240px' },
  { key: 'account', title: '收款账号', dataIndex: 'account' },
  { key: 'ifCode', title: '支付方式', dataIndex: 'ifCode', width: '120px' },
  { key: 'state', title: '状态', scopedSlots: { customRender: 'stateSlot' } },
  { key: 'createdAt', title: '日期', scopedSlots: { customRender: 'dateSlot' } },
  {
    key: 'op',
    title: '操作',
    width: '125px',
    fixed: 'right',
    align: 'center',
    scopedSlots: { customRender: 'opSlot' }
  }
]

export default {
  name: 'IsvListPage',
  components: { JeepayTable, JeepayTableColumns, JeepayTextUp, RefundModal },
  data () {
    return {
      loading: false,
      btnLoading: false,
      tableColumns: tableColumns,
      searchData: {},
      createdStart: '', // 选择开始时间
      createdEnd: '', // 选择结束时间
      visible: false,
      detailData: {},
      payWayList: [],
      isShow: false, // 回调弹出框
      payOrderId: '',
      list: [] // 支付方式下拉
    }
  },
  computed: {},
  async created () {
    const res = await ifCodeList()
    this.list = res
  },
  methods: {
    queryFunc () {
      this.btnLoading = true
      this.$refs.infoTable.refTable(true)
    },
    // 导出
    download () {
      // 开始 结束
      var createdEndDate = new Date(this.searchData.createdStart)
      var successEndDate = new Date(this.searchData.successStart)
      if (!(this.searchData.createdStart && this.searchData.createdEnd) && !(this.searchData.successStart && this.searchData.successEnd)) {
        this.$message.error('订单导出时间选择不能为空')
      } else if (!(moment(this.searchData.createdEnd, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD') <= moment(createdEndDate.setMonth(createdEndDate.getMonth() + 1)).format('YYYY-MM-DD'))) {
        this.$message.error('时间选择为一个月内')
      } else if (!(moment(this.searchData.successEnd, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD') <= moment(successEndDate.setMonth(successEndDate.getMonth() + 1)).format('YYYY-MM-DD'))) {
        this.$message.error('时间选择为一个月内')
      } else {
        this.loading = true
        const iPage = this.$refs.infoTable.iPage
        const pageSize = iPage.pageSize
        const pageNumber = iPage.pageNumber
        download(pageNumber, pageSize, this.searchData.createdStart, this.searchData.createdEnd, this.searchData.successStart,
        this.searchData.successEnd, this.searchData.unionOrderId, this.searchData.account, this.searchData.state,
        this.searchData.liquidateState, this.searchData.ifCode).then(res => {
        this.loading = false
        this.$message.warning('文件正在导出,请稍等...')
        if (res.code !== 9999) {
          if (res.byteLength > 0) {
            this.downloadFile(res)
            // 处理成功的逻辑
          } else {
            this.$message.error('导出失败')
            // 处理失败的逻辑
          }
          this.downloadFile(res)
        } else {
          this.$message.error(res.msg) // 显示异常信息
        }
        }).catch(() => {
          this.loading = false
        })
      }
    },
    downloadFile (data) {
      // 文件导出
      if (!data) {
        return
      }
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', 'payOrder.xlsx')

      document.body.appendChild(link)
      link.click()
    },
    // 请求table接口数据
    reqTableDataFunc: params => {
      return req.list(API_URL_PAY_ORDER_LIST, params)
    },
    searchFunc: function () {
      // 点击【查询】按钮点击事件
      this.$refs.infoTable.refTable(false)
    },
    // 打开回调弹出框
    openFunc (record, payOrderId) {
      this.isShow = true
      this.payOrderId = payOrderId
    },
    handleOk () {
      payOrder(this.payOrderId).then(res => {
        this.$message.success(res)
        this.isShow = false
      })
    },
    // 回调弹出框取消按钮
    handleCancel () {
      this.isShow = false
    },
    cancel () {
      this.isShow = false
    },
    detailFunc: function (id) {
      const that = this
      req.getById(API_URL_PAY_ORDER_LIST, id).then(res => {
        res.amount = formatAmount(res.amount)
        res.mchFeeAmount = formatAmount(res.mchFeeAmount)
        that.detailData = res
      })
      this.visible = true
    },
    moment,
    onChange (date, dateString) {
      this.searchData.createdStart = dateString[0] // 开始时间
      this.searchData.createdEnd = dateString[1] // 结束时间
    },
    onChangeTime (date, dateString) {
      this.searchData.successStart = dateString[0] // 开始时间
      this.searchData.successEnd = dateString[1] // 结束时间
    },
    disabledDate (current) {
      // 今日之后日期不可选
      return current && current > moment().endOf('day')
    },
    onClose () {
      this.visible = false
    },
    changeStr2ellipsis (orderNo, baseLength) {
      const halfLengh = parseInt(baseLength / 2)
      return (
        orderNo.substring(0, halfLengh - 1) +
        '...' +
        orderNo.substring(orderNo.length - halfLengh, orderNo.length)
      )
    }
  }
}
</script>
<style lang="less" scoped>
///deep/ .ant-table-fixed{
//  tr{
//    th{
//      padding: 0px 0px;
//    }
//  }
//  }

.order-list {
  -webkit-text-size-adjust: none;
  font-size: 12px;
  display: flex;
  flex-direction: column;

  p {
    white-space: nowrap;
    span {
      display: inline-block;
      font-weight: 800;
      height: 16px;
      line-height: 16px;
      width: 35px;
      border-radius: 5px;
      text-align: center;
      margin-right: 2px;
    }
  }
}
</style>
