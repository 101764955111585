// 千分位展示与保留两位小数
export function formatAmount (val) {
    val = (val / 100).toLocaleString() // 3,000
    // eslint-disable-next-line eqeqeq
    if (val.indexOf('.') == -1) {
        val = val + '.00' // 3,000.00
        // eslint-disable-next-line eqeqeq
    } else if (val.charAt(val.indexOf('.') == val.length - 2)) {
        val = val + '0'
    }
    return val
}
// 千分位展示与保留两位小数
export function formatAmountNo (val) {
    val = val.toLocaleString() // 3,000
    // eslint-disable-next-line eqeqeq
    if (val.indexOf('.') == -1) {
        val = val + '.00' // 3,000.00
        // eslint-disable-next-line eqeqeq
    } else if (val.charAt(val.indexOf('.') == val.length - 2)) {
        val = val + '0'
    }
    return val
}
